<!-- 프로필 -->

<template>
  <div class="modele-view row">
    <!-- <div class="col-lg-5"> -->
    <div class="profile-card">
      <!-- <small class="web_id"> -->
        <!-- <b-button v-bind:href="webId"> 내 프로필 카드 </b-button> -->
        <!-- <a v-bind:href="webId" target="_blank">{{ webId }}</a> -->
      <!-- </small> -->
      <div>
        <div class="media" style="margin: auto;">
          <a class="pull-left" href="#" style="margin: auto;">
            <img
              class="media-object dp img-circle"
              :src="profile.photo"
              style="width: 100px;height:100px;"
            />
          </a>
        </div>
        <b-alert
          class="row"
          show
          style="padding: 1rem;background-color: #fff;border: none;box-shadow: 2px 2px 16px rgba(0,0,0,0.1);margin-bottom: 1.5rem;"
        >
          <ul
            style="list-style: none; padding: 0;margin: 0;width: 100%;"
          >
            <li
              style="display:flex; align-items:center;flex-wrap: wrap;width: 100%;"
            >
              <p>이름 :</p>
              <EditableDiv
                v-model="profile.name"
                placeholder="이름을 입력해주세요"
                @input="update"
                id="name"
              ></EditableDiv>
            </li>
            <li
              style="display:flex; align-items:center;flex-wrap: wrap;width: 100%;"
            >
              <p>직업 :</p>
              <EditableDiv
                v-model="profile.role"
                placeholder="직책을 입력해주세요"
                @input="update"
                id="role"
              ></EditableDiv>
            </li>
            <li
              style="display:flex; align-items:center;flex-wrap: wrap;width: 100%;"
            >
              <p>직장 :</p>
              <EditableDiv
                v-model="profile.organization"
                placeholder="직장을 입력해주세요"
                @input="update"
                id="org"
              ></EditableDiv>
            </li>
          </ul>

          <!-- <ul>
          <small>
            <li><u>is a</u></li>
            <li v-if="profile.role">rdf:type</li>
          </small>
        </ul> -->

          <!-- <ul>
          <small>
            <li>at</li>
            <li v-if="profile.organization">
              <code
                >@prefix org: {{ org }}. <br />
                org:subOrganizationOf</code
              >
            </li>
          </small>
        </ul> -->
        </b-alert>

        <small
          style="display: flex; flex-direction:column; justify-content:flex-start;"
        >
          <b-alert
            class="row"
            show
            style=" background-color: #fff;border: none;box-shadow: 2px 2px 16px rgba(0,0,0,0.1);width:100%"
          >
            <ul
              style="list-style:none; font-size: 0.9rem; display: flex;padding: 0; align-items: center;width:100%; flex-wrap: wrap; margin: 0"
            >
              <li>
                <p>내가 사는 도시 :</p>
              </li>
              <li>
                <EditableDiv
                  v-model="profile.locality"
                  placeholder="거주 도시"
                  @input="update"
                ></EditableDiv>
              </li>
              <li v-if="profile.locality">
                <code>{{ location }}</code>
              </li>
            </ul>

            <ul
            style="list-style:none; font-size: 0.9rem; display: flex;padding: 0; align-items: center;width:100%; flex-wrap: wrap; margin: 0"
            >
              <li><p>국가 :</p></li>
              <li>
                <EditableDiv
                  v-model="profile.country"
                  placeholder="거주 국가"
                  @input="update"
                ></EditableDiv>
              </li>
              <li v-if="profile.country">
                <code>{{ org }}<br /> </code>
              </li>
            </ul>
          </b-alert>
          <!--          (todo see js console BUG #1)-->
        </small>
      </div>
      <hr />
      <small class="web_id">
        <b-button class="btn" v-bind:href="webId"> 내 프로필 카드 </b-button>
      </small>

      <!--  <EditableDiv   v-if="profile.organization" v-model="profile.organization" placeholder="YOUR ORGANIZATION" @input="update">
        </EditableDiv>
      org:hasMember <small><i>(Todo : getmembers())</i></small>-->
    </div>

    <!--  <b-button v-b-modal.profile-editor>Edit</b-button>



      <b-modal id="profile-editor" :title="profile.webId">
      <img class="m-3" width="80" height="80" :src="profile.photo" rounded="circle" alt=" " /><br>

      <div>
      <b-form-group label-cols-sm="3"
      label="Name:"
      label-align-sm="right"
      label-for="name">
      <b-form-input id="name" v-model="profile.name"></b-form-input>
    </b-form-group>


    <b-form-group
    label-cols-sm="3"
    label="Organization:"
    label-align-sm="right"
    label-for="organization">
    <b-form-input id="organization" v-model="profile.organization"></b-form-input>
  </b-form-group>

  <b-form-group
  label-cols-sm="3"
  label="Role:"
  label-align-sm="right"
  label-for="role">
  <b-form-input id="role" v-model="profile.role"></b-form-input>
</b-form-group>

<b-form-group
label-cols-sm="3"
label="Birthday:"
label-align-sm="right"
label-for="bday">
<b-form-input id="bday" type="date" v-model="profile.bday"></b-form-input>
</b-form-group>

<b-form-group
label-cols-sm="3"
label="Gender:"
label-align-sm="right"
label-for="gender">
<b-form-input id="gender" v-model="profile.gender"></b-form-input>
</b-form-group>


<b-form-group
label-cols-sm="3"
label="Address Locality:"
label-align-sm="right"
label-for="address">
<b-form-input id="address" v-model="profile.locality"></b-form-input>
</b-form-group>

<b-form-group
label-cols-sm="3"
label="Note:"
label-align-sm="right"
label-for="note">
<b-form-input id="note" v-model="profile.note"></b-form-input>
</b-form-group>
</div>
</b-modal>
-->
  </div>
</template>

<script>
//import {  fetchDocument } from 'tripledoc';
//import {  rdf} from 'rdf-namespaces'
//import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: "SolidProfile",
  components: {
    EditableDiv: () => import("@/components/basic/EditableDiv"),
  },
  //  mixins: [ToastMixin],
  props: ["value"],
  data() {
    return {
      location: "<http://schema.org/location>",
      org: "<http://www.w3.org/ns/org#>",
    };
  },
  created() {
    //  console.log("route",this.$route)
    //  this.url = this.$route.params.url
    //  this.getData()
  },
  computed: {
    webId() {
      return this.$store.state.solid.webId;
    },
    profile() {
      return this.$store.state.solid.profile;
    },

    profile_url: {
      get: function() {
        return this.$store.state.solid.storage + "public/salut/profile.ttl";
      },
      set: function() {},
    },
  },
  methods: {
    update() {
      console.log(this.profile);
      this.$store.dispatch("solid/updateProfile", this.profile);
    },
  },
};
</script>

<!-- BEGINNING OF STYLE CSS -->
<style scoped>

.profile-card {
    flex: 0 0 100%;
    max-width: 100%;
}

.profile {
  text-align: left;
}
.media {
  /*box-shadow:0px 0px 4px -2px #000;*/
  margin: 20px 0;
  padding: 30px;
}
.dp {
  border: 10px solid #eee;
  transition: all 0.2s ease-in-out;
}
.dp:hover {
  border: 2px solid #eee;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  /*-webkit-font-smoothing:antialiased;*/
}

p {
  margin: 0;
  font-size: 14px;
  color: #666;
}
#name,
#role,
#org {
  margin: 0;
  padding: 0;
}
.row {
  margin: 0;
}
code {
  font-size: 12px;
}
small.web_id {
  width: 100%;
  
}
small .btn {
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  width: 100%;
  height: 50px;
}

</style>
